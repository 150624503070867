body{
    /*background:linear-gradient(to bottom, #5f82ba, grey);*/
    margin:0;
    padding:0;
    box-sizing:border-box;
    color:white;
  }
  
  .container{
    display:flex;
      align-items:center;
      justify-content:center;
      /*height:100vh;*/
      width:100%;
  }
    .form-box{
        width:380px;
        height:450px;
        background-color:black;
        padding:20px;
        display:flex;
        flex-direction:column;
        justify-content:flex-start;
        opacity:0.5;
        border-radius:2%;
    }
  .header-form{
    margin-bottom:15px;
  }
  button{
    /*margin-top:40px;*/
    margin-bottom:10px;
  }
  .message{
    display:flex;
    justify-content:space-between
  }
  