
.invoice {
    background: #fff;
    padding: 20px
}

.invoiceCompany {
    font-size: 20px
}

.invoiceHeader {
    margin: 0 -20px;
    background: #f0f3f4;
    padding: 20px
}

.invoiceDate,
.invoiceFrom,
.invoiceTo {
    display: table-cell;
    width: 1%
}

.invoiceFrom,
.invoiceTo {
    padding-right: 20px
}

.invoiceDate .date,
.invoiceFrom strong,
.invoiceTo strong {
    font-size: 16px;
    font-weight: 600
}

.invoiceDate {
    text-align: right;
    padding-left: 20px
}

.invoicePrice {
    background: #f0f3f4;
    display: table;
    width: 100%
}

.invoicePrice .invoicePriceLeft,
.invoicePrice .invoicePriceRight {
    display: table-cell;
    padding: 20px;
    font-size: 20px;
    font-weight: 600;
    width: 75%;
    position: relative;
    vertical-align: middle
}

.invoicePrice .invoicePriceLeft .subPrice {
    display: table-cell;
    vertical-align: middle;
    padding: 0 20px
}

.invoicePrice small {
    font-size: 12px;
    font-weight: 400;
    display: block
}

.invoicePrice .invoicePriceRow {
    display: table;
    float: left
}

.invoicePrice .invoicePriceRight {
    width: 25%;
    background: #2d353c;
    color: #fff;
    font-size: 28px;
    text-align: right;
    vertical-align: bottom;
    font-weight: 300
}

.invoicePrice .invoicePriceRight small {
    display: block;
    opacity: .6;
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 12px
}

.invoiceFooter {
    border-top: 1px solid #ddd;
    padding-top: 10px;
    font-size: 10px
}

.invoiceNote {
    color: #999;
    margin-top: 80px;
    font-size: 85%
}

.invoice>div:not(.invoiceFooter) {
    margin-bottom: 20px
}

.btn.btn-white, .btn.btn-white.disabled, .btn.btn-white.disabled:focus, .btn.btn-white.disabled:hover, .btn.btn-white[disabled], .btn.btn-white[disabled]:focus, .btn.btn-white[disabled]:hover {
    color: #2d353c;
    background: #fff;
    border-color: #d9dfe3;
}