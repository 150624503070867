body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.order .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.react-bootstrap-table-sort-order .caret{
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}
.modal {
  background: rgba(0, 0, 0, 0.6);
}
.modal-container {
  position: absolute;
  width: 40%;
  background: #ffffff;
  left: 25%;
  top: 5%;
  padding: 20px;
}

/* Important part */
.modal-items{
  position: absolute;
  background: #ffffff;
  left: 25%;
  top: 15%;
  padding: 20px;
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}
.modal-items-content{
  height: 80vh;
  overflow-y: auto;
}

.dataTables_filter {
  float: left !important;
}

.dataTables_length{
  float: right !important;
}

.grey-bg{
  background-color: #F5F5F5;
}

.bg-mono-navy{
  background-image: linear-gradient(rgb(0, 70, 128),rgb(0, 70, 128)) !important;
}

.bg-blue{
  background-image: linear-gradient(#299be4,rgb(0, 70, 128)) !important;
}

.bg-red{
  background-image: linear-gradient(#dd4b39,#a21b1b) !important;
}

.bg-yellow{
  background-image:linear-gradient(#f39c12 , #f39c12 ) !important;
}

.bg-green{
  background-image:linear-gradient(#4d9474 , #00a65a) !important;
}

.nav-link-menu{
  color: #fff;
  padding: 10px 15px;
    margin: 10px 3px;
    position: relative;
    display: inline-flex;
    line-height: 40px;

}

.nav-link-menu-cart{
  color: #fff;
  padding: 10px 15px;
    margin: 10px 3px;
    position: relative;
    display: inline-flex;
    line-height: 40px;
    margin-right:0 !important;
}

.nav-link-menu:hover{
  color: #8FB6D5;
  padding: 10px 15px;
    margin: 10px 3px;
    position: relative;
    display: inline-flex;
    line-height: 40px;
}

.btn-link{
  color:#fff;
}

.editLot .btn-link{
    font-weight: 400;
    color: #007bff;
    text-decoration: none;
}

.scrollTable{
  overflow-y: scroll;
  float:left;
  width:50%;
  height:calc(100vh - 40px);
  }

  .required:after {
    content:" *";
    color: red;
  }
  
  .card label.required{
    color: red;
  }