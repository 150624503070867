.tab {
    color: #007bff;
    background-color: #fff;
    border:none;
    display: block;
    padding: 0.5rem 1rem;
}

.tab.active {
    background: #fff;
    color: #000;
    border: 1px solid #ddd;
    border-bottom:none;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.well {
    min-width: 90%;
    padding: 15px;
  }